export const MuiDialog = {
    styleOverrides: {
        paper: ({ ownerState, theme }) => ({
            width: "100%",
            borderRadius: "16px",
        }),
    },
};

export const MuiDialogTitle = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            padding: "24px",
            ...theme.typography.h6,
        }),
    },
};

export const MuiDialogContent = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            padding: "10px 24px!important",
            gap: theme.spacingTheme["3"],
        }),
    },
};

export const MuiDialogActions = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            gap: theme.spacingTheme["1.5"],
            padding: "24px",
        }),
    },
};
