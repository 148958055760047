export function clone(element) {
    if (Array.isArray(element)) {
        let newElement = [];
        for (let index in element) {
            newElement.push(Object.assign({}, element[index]));
        }
        return newElement;
    } else {
        return Object.assign({}, element);
    }
}

export function cloneJson(element) {
    return JSON.parse(JSON.stringify(element));
}
