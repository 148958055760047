import { createPaletteChannel, varAlpha } from "../Utils/utils";
import COLORS from "./colors.json";

// ----------------------------------------------------------------------

// Grey
export const grey = createPaletteChannel(COLORS.grey);

// Primary
export const primary = createPaletteChannel(COLORS.primary);

// Secondary
export const secondary = createPaletteChannel(COLORS.secondary);
export const secondary2 = createPaletteChannel(COLORS.secondary2);
export const secondary3 = createPaletteChannel(COLORS.secondary3);

// Tertiary
export const tertiary = createPaletteChannel(COLORS.tertiary);
export const tertiary2 = createPaletteChannel(COLORS.tertiary2);

// Info
export const info = createPaletteChannel(COLORS.info);

// Success
export const success = createPaletteChannel(COLORS.success);

// Warning
export const warning = createPaletteChannel(COLORS.warning);

// Error
export const error = createPaletteChannel(COLORS.error);

// Common
export const common = createPaletteChannel(COLORS.common);

// Globals
export const globals = {
    inherit: {
        light: createPaletteChannel({
            color: common.white,
            bgcolor: grey[800],
            hover: grey[700],
        }),
        dark: createPaletteChannel({
            color: grey[800],
            bgcolor: common.white,
            hover: grey[400],
        }),
    },
    default: {
        light: createPaletteChannel({
            color: grey[800],
            bgcolor: grey[300],
            hover: grey[400],
        }),
        dark: createPaletteChannel({
            color: grey[800],
            bgcolor: grey[300],
            hover: grey[400],
        }),
    },
};

// Text
export const text = {
    light: createPaletteChannel({
        primary: grey[800],
        secondary: grey[600],
        disabled: grey[500],
    }),
    dark: createPaletteChannel({
        primary: "#FFFFFF",
        secondary: grey[500],
        disabled: grey[600],
    }),
};

// Background
export const background = {
    light: createPaletteChannel({
        paper: "#FFFFFF",
        default: "#FFFFFF",
        neutral: grey[200],
    }),
    dark: createPaletteChannel({
        paper: grey[800],
        default: grey[900],
        neutral: "#28323D",
    }),
};

// Action
export const baseAction = {
    hover: varAlpha(grey["500Channel"], 0.08),
    selected: varAlpha(grey["500Channel"], 0.16),
    selectedListItem: varAlpha(primary["mainChannel"], 0.08),
    focus: varAlpha(grey["500Channel"], 0.24),
    disabled: varAlpha(grey["500Channel"], 0.8),
    disabledBackground: varAlpha(grey["500Channel"], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
};

export const action = {
    light: { ...baseAction, active: grey[600] },
    dark: { ...baseAction, active: grey[500] },
};

/*
 * Base palette
 */
export const basePalette = {
    primary,
    secondary,
    secondary2,
    secondary3,
    tertiary,
    tertiary2,
    info,
    success,
    warning,
    error,
    grey,
    common,
    divider: varAlpha(grey["500Channel"], 0.2),
    action,
};

export const lightPalette = {
    ...basePalette,
    text: text.light,
    background: background.light,
    action: action.light,
    globals: {
        inherit: globals.inherit.light,
        default: globals.default.light,
    },
};

export const darkPalette = {
    ...basePalette,
    text: text.dark,
    background: background.dark,
    action: action.dark,
    globals: {
        inherit: globals.inherit.dark,
        default: globals.default.dark,
    },
};

// ----------------------------------------------------------------------

export const colorSchemes = {
    light: { palette: lightPalette },
    dark: { palette: darkPalette },
};
