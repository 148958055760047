export const routeBo = {
    // MANAGER
    api_bo_manager_app_user_list: "/api/bo/manager/app-user/list",
    api_bo_manager_app_user_add: "/api/bo/manager/app-user/add",
    api_bo_manager_app_user_edit: "/api/bo/manager/app-user/{uuid}",
    api_bo_manager_app_user_resend: "/api/bo/manager/app-user/{uuid}/resend",
    api_bo_manager_app_user_delete_request_list: "/api/bo/manager/app-user/delete-request/list",

    api_bo_manager_app_role_list: "/api/bo/manager/app-role/list",
    api_bo_manager_app_role_add: "/api/bo/manager/app-role/add",
    api_bo_manager_app_role_edit: "/api/bo/manager/app-role/{id}",

    api_bo_manager_app_space_list: "/api/bo/manager/app-space/list",
    api_bo_manager_app_space_add: "/api/bo/manager/app-space/add",
    api_bo_manager_app_space_edit: "/api/bo/manager/app-space/{uuid}",
    api_bo_manager_app_space_get: "/api/bo/manager/app-space/{slug}",
    api_bo_manager_app_space_users_add: "/api/bo/manager/app-space/{uuid}/users/add",
    api_bo_manager_app_space_users_list: "/api/bo/manager/app-space/{slug}/users/list",
    api_bo_manager_app_space_users_delete: "/api/bo/manager/app-space/{slug}/users/delete",
    api_bo_manager_app_space_user_edit: "/api/bo/manager/app-space/{slug}/user/{uuid}",

    api_bo_manager_license_list: "/api/bo/manager/license/list",
    api_bo_manager_license_add: "/api/bo/manager/license/add",
    api_bo_manager_license_get: "/api/bo/manager/license/{uuid}",
    api_bo_manager_license_edit: "/api/bo/manager/license/{uuid}",

    // LEARNING
    api_bo_learning_course_list: "/api/bo/learning/course/list",
    api_bo_learning_course_add: "/api/bo/learning/course/add",
    api_bo_learning_course_edit: "/api/bo/learning/course/{id}",

    api_bo_learning_package_list: "/api/bo/learning/package/list",
    api_bo_learning_package_add: "/api/bo/learning/package/add",
    api_bo_learning_package_get: "/api/bo/learning/package/{id}",
    api_bo_learning_package_edit: "/api/bo/learning/package/{id}",

    // ADMINISTRATOR
    api_bo_administrator_role_list: "/api/bo/administrator/role/list",
    api_bo_administrator_role_add: "/api/bo/administrator/role/add",
    api_bo_administrator_role_edit: "/api/bo/administrator/role/{id}",

    api_bo_administrator_user_list: "/api/bo/administrator/user/list",
    api_bo_administrator_user_add: "/api/bo/administrator/user/add",
    api_bo_administrator_user_edit: "/api/bo/administrator/user/{uuid}",
    api_bo_administrator_user_edit_resend: "/api/bo/administrator/user/{uuid}/resend",

    // TAGS
    api_bo_tag_list: "/api/bo/tag/list",
    api_bo_tag_category_tabs_list: "/api/bo/tag/categoryTabs/list",
    api_bo_tag_category_add: "/api/bo/tag/category/add",
    api_bo_tag_category_edit: "/api/bo/tag/category/{id}",
    api_bo_tag_category_delete: "/api/bo/tag/category/{id}/delete",
    api_bo_tag_category_tabs_tagTabs: "/api/bo/tag/categoryTabs/{id}/tagTabs",
    api_bo_tag_add: "/api/bo/tag/{id}/add",
    api_bo_tag_edit: "/api/bo/tag/{id}",
    api_bo_tag_delete: "/api/bo/tag/{id}/delete",

    // SELECT
    api_bo_select_role: "/api/bo/select/role",
    api_bo_select_package: "/api/bo/select/package",

    // AUTOCOMPLETE
    api_bo_auto_complete_users: "/api/bo/auto-complete/users",
    api_bo_auto_complete_courses: "/api/bo/auto-complete/courses",
};
