import { createSlice } from "@reduxjs/toolkit";

export const SnackBarSlice = createSlice({
    name: "SnackBarReducer",
    initialState: [
        {
            isOpen: false,
            variant: "",
            duration: 3000,
            text: "",
        },
    ],
    reducers: {
        SnackbarOpen(state, action) {
            const { duration = 3000, ...rest } = action.payload;
            return [...state, { ...rest, duration, isOpen: true }];
        },
        SnackbarClose(state) {
            return state.map((snackbar) => ({ ...snackbar, isOpen: false }));
        },
    },
});

export const { SnackbarOpen, SnackbarClose } = SnackBarSlice.actions;
export default SnackBarSlice.reducer;
