import { darkenHexColor, hexToRgbChannel, varAlpha } from "../Core/Utils/utils";

export const MuiChip = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            height: ownerState.size === "small" ? "24px" : "32px",
            minWidth: ownerState.size === "small" ? "24px" : "32px",
            gap: "6px",
            padding: "6px",
            borderRadius: "6px",
            fontSize: "12px",
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: "20px",
            ...getColorsByState(ownerState, theme),
            "& .MuiChip-label": {
                padding: 0,
            },
            "& .MuiChip-icon": {
                margin: 0,
            },
            "& .MuiChip-deleteIcon": {
                margin: 0,
            },
        }),
    },
};

const getColorsByState = (state, theme) => {
    const colorCustom = state.colorcustom ? state.colorcustom : theme.palette.grey["500"];

    switch (state.variant) {
        case "primary-light":
            return {
                color: theme.palette.primary.dark,
                backgroundColor: varAlpha(theme.palette.primary["mainChannel"], 0.16),
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.primary.dark,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.primary.dark, 25),
                },
            };
        case "secondary-light":
            return {
                color: theme.palette.secondary.dark,
                backgroundColor: varAlpha(theme.palette.secondary["mainChannel"], 0.16),
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.secondary.dark,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.secondary.dark, 25),
                },
            };
        case "secondary2-light":
            return {
                color: theme.palette.secondary2.dark,
                backgroundColor: varAlpha(theme.palette.secondary2["mainChannel"], 0.16),
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.secondary2.dark,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.secondary2.dark, 25),
                },
            };
        case "error-light":
            return {
                color: theme.palette.error.dark,
                backgroundColor: varAlpha(theme.palette.error["mainChannel"], 0.16),
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.error.dark,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.error.dark, 25),
                },
            };
        case "warning-light":
            return {
                color: theme.palette.warning.dark,
                backgroundColor: varAlpha(theme.palette.warning["mainChannel"], 0.16),
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.warning.dark,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.warning.dark, 25),
                },
            };
        case "success-light":
            return {
                color: theme.palette.success.dark,
                backgroundColor: varAlpha(theme.palette.success["mainChannel"], 0.16),
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.success.dark,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.success.dark, 25),
                },
            };
        case "info-light":
            return {
                color: theme.palette.info.dark,
                backgroundColor: varAlpha(theme.palette.info["mainChannel"], 0.16),
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.info.dark,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.info.dark, 25),
                },
            };
        case "custom":
            return {
                color: theme.palette.common.white,
                backgroundColor: colorCustom,
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.common.white,
                },
            };
        case "custom-light":
            return {
                color: colorCustom,
                backgroundColor: varAlpha(hexToRgbChannel(colorCustom), 0.16),
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: colorCustom,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(colorCustom, 25),
                },
            };
        case "outlined-grey":
            return {
                color: theme.palette.grey["800"],
                border: `2px solid ${theme.palette.grey["800"]}`,
                backgroundColor: theme.palette.common.white,
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.grey["800"],
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.grey["800"], 25),
                },
            };
        case "outlined-secondary2":
            return {
                color: theme.palette.secondary2.main,
                border: `2px solid ${theme.palette.secondary2.main}`,
                backgroundColor: theme.palette.common.white,
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.secondary2.main,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.secondary2.main, 25),
                },
            };
        case "outlined-info":
            return {
                color: theme.palette.info.main,
                border: `2px solid ${theme.palette.info.main}`,
                backgroundColor: theme.palette.common.white,
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: theme.palette.info.main,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(theme.palette.info.main, 25),
                },
            };
        case "outlined-custom":
            return {
                color: colorCustom,
                border: `1px solid ${colorCustom}`,
                backgroundColor: theme.palette.common.white,
                "& .MuiChip-icon, & .MuiChip-deleteIcon": {
                    color: colorCustom,
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: darkenHexColor(colorCustom, 25),
                },
            };
    }

    switch (state.color) {
        case "default":
            return {
                color: theme.palette.text.secondary,
                backgroundColor: varAlpha(theme.palette.grey["500Channel"], 0.16),
            };
    }
    return {};
};
