import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import AuthentificationSlice from "./Reducer/AuthenticationReducer/AuthentificationSlice";
import DrawerMainLayoutSlice from "./Reducer/DrawerMainLayoutReducer/DrawerMainLayoutSlice";
import ScreenSlice from "./Reducer/ScreenReducer/ScreenSlice";
import SnackBarSlice from "./Reducer/SnackBarReducer/SnackBarSlice";
import TableSlice from "./Reducer/TableReducer/TableSlice";

const rootReducer = {
    AuthenticationReducer: AuthentificationSlice,
    ScreenReducer: ScreenSlice,
    SnackBarReducer: SnackBarSlice,
    TableReducer: TableSlice,
    DrawerMainLayoutReducer: DrawerMainLayoutSlice,
};

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["SnackBarReducer", "ScreenReducer", "TableReducer"],
    whitelist: ["AuthenticationReducer", "DrawerMainLayoutReducer"],
};

const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer));

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
                ignoredActionPaths: ["register", "rehydrate"],
                ignoredPaths: ["SnackBarReducer.register"],
            },
        }),
});

const persistor = persistStore(store);

export { store, persistor };
