import i18n from "i18next";
import moment from "moment";
import momentFR from "moment-locale-fr";

import React from "react";
import { initReactI18next } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router";

import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import DefaultTheme from "Theme/DefaultTheme";

import ApiHandler from "Handler/ApiHandler";
import { getRoutingFunctions, loadRoutingFunctions } from "Handler/RouteHandler/RoutingFunctionHandler";
import { AuthenticationLogin } from "Reducer/AuthenticationReducer/AuthentificationSlice";
import { ScreenChange } from "Reducer/ScreenReducer/ScreenSlice";
import SnackBarComponent from "Reducer/SnackBarReducer/SnackBarComponent";

import * as apiMessagesEN from "./locales/en/apiMessages.json";
import * as commonEN from "./locales/en/common.json";
import * as enumsEN from "./locales/en/enums.json";
import * as fieldsEN from "./locales/en/fields.json";
import * as formEN from "./locales/en/form.json";
import * as routingEN from "./locales/en/routing.json";
import * as snackbarEN from "./locales/en/snackbar.json";
import * as apiMessagesFR from "./locales/fr/apiMessages.json";
import * as commonFR from "./locales/fr/common.json";
import * as enumsFR from "./locales/fr/enums.json";
import * as fieldsFR from "./locales/fr/fields.json";
import * as formFR from "./locales/fr/form.json";
import * as routingFR from "./locales/fr/routing.json";
import * as snackbarFR from "./locales/fr/snackbar.json";

//------ SubDomain --------//
function getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");

    if (parts.length > 2) {
        if (hostname.includes("dev-")) {
            parts[0] = parts[0].replace(/dev-|-v2/g, "");
            return parts[0];
        } else if (hostname.includes("my-preprod")) {
            if (parts[0].startsWith("bo-")) {
                return "bo";
            } else if (parts[0].startsWith("sso-")) {
                return "sso";
            } else if (parts[0] === "my-preprod") {
                return "app";
            }
        } else if (hostname.includes("my.simforhealth.com")) {
            if (parts[0].startsWith("bo-")) {
                return "bo";
            } else if (parts[0].startsWith("sso-")) {
                return "sso";
            } else if (parts[0] === "my") {
                return "app";
            }
        }
    }
    return null;
}

//------ Dispatch --------//
export let dispatch;

//------- Routes Nav --------//
export let navRoutes;

function App() {
    dispatch = useDispatch();
    const [routes, setRoutes] = React.useState(false);
    const [routeDefault, setRouteDefault] = React.useState("");
    const [isReady, setReady] = React.useState(false);
    const subdomain = getSubdomain();
    const authenticationReducer = useSelector((state) => state.AuthenticationReducer);

    const firstSpace = new URLSearchParams(window.location.search).get("space");

    navRoutes = routes;

    //------ Locale --------//
    function loadLocale(lang) {
        i18n.use(initReactI18next).init({
            resources: {
                fr: {
                    common: commonFR,
                    form: formFR,
                    snackbar: snackbarFR,
                    routing: routingFR,
                    fields: fieldsFR,
                    apiMessages: apiMessagesFR,
                    enums: enumsFR,
                },
                en: {
                    common: commonEN,
                    form: formEN,
                    snackbar: snackbarEN,
                    routing: routingEN,
                    fields: fieldsEN,
                    apiMessages: apiMessagesEN,
                    enums: enumsEN,
                },
            },
            lng: lang, // Default language
            fallbackLng: "fr",
            ns: ["common", "form", "snackbar", "routing", "fields", "apiMessages", "enums"],
            defaultNS: "common",
            interpolation: {
                escapeValue: false,
            },
        });
        //------ Moment --------//
        if (lang === "fr") {
            moment.updateLocale("fr", momentFR);
        }
        moment.locale(lang);
    }

    //---------- Screen --------------//
    const resize = () => {
        let screen = "";

        if (window.innerWidth < 576) screen = "XS";
        if (window.innerWidth >= 576 && window.innerWidth < 768) screen = "SM";
        if (window.innerWidth >= 768 && window.innerWidth < 992) screen = "MD";
        if (window.innerWidth >= 992 && window.innerWidth < 1200) screen = "LG";
        if (window.innerWidth >= 1200) screen = "XL";
        dispatch(
            ScreenChange({
                screen: screen,
                size: window.innerWidth,
                orientation: window.screen.orientation && window.screen.orientation.angle === 90,
            }),
        );
    };

    React.useEffect(() => {
        window.addEventListener("resize", resize);
        window.addEventListener("orientationchange", resize);
        resize();

        return () => {
            window.removeEventListener("resize", resize);
            window.removeEventListener("orientationchange", resize);
        };
    }, []);

    //---------- Routing --------------//

    React.useEffect(() => {
        async function loadRouting() {
            await loadRoutingFunctions(subdomain);
            const { getPathDefault, getRoutes, updateRouting } = getRoutingFunctions();
            let info = {};
            ApiHandler.get({ route: "api_default" }, (response) => {
                if (response && response.status === 200 && response.data.authenticated) {
                    let user = response.data.user;

                    info = {
                        uuid: user.uuid,
                        email: user.email,
                        roles: user.roles,
                        subdomain: response.data.subdomain,
                        lastname: user.lastname,
                        firstname: user.firstname,
                        spaces: user.spaces,
                        selectedSpace: firstSpace
                            ? firstSpace
                            : user.spaces.find((space) => space.uuid === authenticationReducer.selectedSpace)
                              ? authenticationReducer.selectedSpace
                              : user.spaces.length > 0 // Prévenir BO_USER sans APP_USER
                                ? user.spaces[0].uuid
                                : "",
                        hasBo: user.hasBo,
                        language: user.language,
                    };
                    dispatch(AuthenticationLogin(info));
                }
                loadLocale(info.language);
                updateRouting(info);
                setRoutes(getRoutes());
                setRouteDefault(getPathDefault());
                setReady(true);
            });
        }

        loadRouting();
    }, [subdomain, authenticationReducer.selectedSpace]);

    if (isReady && routes) {
        return (
            <ThemeProvider theme={DefaultTheme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <BrowserRouter
                        future={{
                            v7_relativeSplatPath: true,
                            v7_startTransition: true,
                        }}>
                        <Routes>
                            {Object.keys(routes).map((key) => {
                                const route = routes[key];
                                return (
                                    <Route
                                        key={"route_" + key}
                                        path={route.path}
                                        element={<route.layout view={route.view} />}
                                    />
                                );
                            })}
                            <Route path="*" element={<Navigate to={routeDefault} replace />} />
                        </Routes>
                        <SnackBarComponent />
                    </BrowserRouter>
                </LocalizationProvider>
            </ThemeProvider>
        );
    }
}

export default App;
