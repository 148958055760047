import { createSlice } from "@reduxjs/toolkit";

export const AuthentificationSlice = createSlice({
    name: "AuthenticationReducer",
    initialState: {
        email: "",
        roles: [],
        subdomain: "",
        firstname: "",
        lastname: "",
        spaces: [],
        selectedSpace: "",
        hasBo: "",
        language: "",
    },
    reducers: {
        AuthenticationLogin(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },
        AuthenticationLogout(state) {
            state.email = "";
            state.roles = [];
            state.subdomain = "";
            state.firstname = "";
            state.lastname = "";
            state.spaces = [];
            state.selectedSpace = "";
            state.hasBo = "";
            state.language = "";
        },
    },
});

export const { AuthenticationLogin, AuthenticationLogout } = AuthentificationSlice.actions;
export default AuthentificationSlice.reducer;
