import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    screen: "",
    size: "",
    orientation: false,
    full: false,
};

const ScreenSlice = createSlice({
    name: "ScreenReducer",
    initialState,
    reducers: {
        ScreenChange(state, action) {
            return { ...state, ...action.payload };
        },
        ScreenFull(state, action) {
            state.full = action.payload.full;
        },
    },
});

export const { ScreenChange, ScreenFull } = ScreenSlice.actions;
export default ScreenSlice.reducer;
