export const routeApp = {
    // DASHBOARD
    api_app_dashboard_space_get: "/api/app/dashboard/space/{uuid}",

    // TRAINING
    api_app_training_list: "/api/app/training/list",
    api_app_training_get: "/api/app/training/{id}",
    api_app_training_course_get: "/api/app/training/{id}/course/{course}",
    api_app_training_course_update_data: "/api/app/training/{id}/course/{course}/update-data",
    api_app_training_course_end_package_export: "/api/app/training/{id}/course/{course}/end-package-export",

    // USER MANAGER
    api_app_user_manager_app_space_learners_list: "/api/app/user-manager/app-space/{slug}/learners/list",
    api_app_user_manager_app_space_formers_list: "/api/app/user-manager/app-space/{slug}/formers/list",
    api_app_user_manager_app_space_users_add: "/api/app/user-manager/app-space/{slug}/users/add",
    api_app_user_manager_app_space_users_delete: "/api/app/user-manager/app-space/{slug}/users/delete",
    api_app_user_manager_app_space_user_edit: "/api/app/user-manager/app-space/{slug}/user/{uuid}",
    api_app_user_manager_app_space_user_resend: "/api/app/user-manager/app-space/{slug}/user/{uuid}/resend",

    api_app_user_manager_group_list: "/api/app/user-manager/group/list",
    api_app_user_manager_group_get: "/api/app/user-manager/group/{uuid}",
    api_app_user_manager_group_add: "/api/app/user-manager/group/add",
    api_app_user_manager_group_edit: "/api/app/user-manager/group/{uuid}",
    api_app_user_manager_group_check_add: "/api/app/user-manager/group/{uuid}/check-add",
    api_app_user_manager_group_delete: "/api/app/user-manager/group/{uuid}",
    api_app_user_manager_group_check_delete: "/api/app/user-manager/group/{uuid}/check-delete",
    api_app_user_manager_group_user_delete: "/api/app/user-manager/group/user/delete",
    api_app_user_manager_group_license_get: "/api/app/user-manager/group/{uuid}/license/get",
    api_app_user_manager_group_user_license_deactivate: "/api/app/user-manager/group/user/license/{id}/deactivate",

    // SESSIONS
    api_app_learner_session_training_manager_list: "/api/app/learner/session-training-manager/list",
    api_app_learner_session_training_manager_get: "/api/app/learner/session-training-manager/{id}",
    api_app_learner_session_training_manager_add: "/api/app/learner/session-training-manager/add",
    api_app_learner_session_training_manager_edit: "/api/app/learner/session-training-manager/{id}",
    api_app_learner_session_training_manager_check_add: "/api/app/learner/session-training-manager/checkAdd",
    api_app_learner_session_training_manager_archived: "/api/app/learner/session-training-manager/{id}/archived",
    api_app_learner_session_training_manager_license_packages_list:
        "/api/app/learner/session-training-manager/license-packages/list",
    api_app_learner_session_training_manager_courses_list: "/api/app/learner/session-training-manager/courses/list",
    api_app_learner_session_training_manager_check_availability_license_package:
        "/api/app/learner/session-training-manager/check-availability/license-package",
    api_app_learner_session_training_manager_results: "/api/app/learner/session-training-manager/{id}/results",

    // FORMER
    api_app_former_course_list: "/api/app/former/course/list",
    api_app_former_course_get: "/api/app/former/course/{course}",

    // SETTING
    api_app_setting_my_account_list: "/api/app/setting/my-account/list",
    api_app_setting_my_account_info_edit: "/api/app/setting/my-account/info/{uuid}",
    api_app_setting_my_account_info_password_change: "/api/app/setting/my-account/info/{uuid}/password/change",
    api_app_setting_my_account_info_delete_account: "/api/app/setting/my-account/info/{uuid}/delete-account",
    api_app_setting_my_account_info_delete_account_cancel:
        "/api/app/setting/my-account/info/{uuid}/delete-account/cancel",

    // SELECT
    api_app_select_country: "/api/app/select/country",
    api_app_select_hourFormat: "/api/app/select/hour-format",
    api_app_select_language: "/api/app/select/language",
    api_app_select_professionalStatus: "/api/app/select/professional-status",
    api_app_select_role: "/api/app/select/role",
    api_app_select_spaces: "/api/app/select/spaces",
    api_app_select_space_groups: "/api/app/select/space/{slug}/groups",
    api_app_select_space_courses: "/api/app/select/space/{slug}/courses",
    api_app_select_space_trainings: "/api/app/select/space/{slug}/trainings",
    api_app_select_space_courses_recap: "/api/app/select/space/{slug}/courses/recap",
    api_app_select_courses: "/api/app/select/courses",
    api_app_select_course_get: "/api/app/select/course/{id}",
    api_app_select_trainings: "/api/app/select/trainings",

    // AUTOCOMPLETE
    api_app_auto_complete_users: "/api/app/auto-complete/users",
    api_app_auto_complete_users_emails: "/api/app/auto-complete/users/only-email",
    api_app_auto_complete_spaces: "/api/app/auto-complete/spaces",
    api_app_auto_complete_users_space: "/api/app/auto-complete/users/space",
    api_app_auto_complete_tag: "/api/app/auto-complete/tag",

    // FILE HANDLER
    api_app_file_handler_parse_csv_email: "/api/app/file-handler/parse/csv/email",
    api_app_file_handler_delete_asset: "/api/app/file-handler/delete/asset",
};
